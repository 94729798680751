import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import BrandSection from "../../components/brandSection";
import ContactSection from "../../components/contactSection";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import "./style.scss";
import ProjectCards from "../../components/projectCards";



const Home = () => {
  return (
    <>
      <Helmet>
        <title>Stact - Consulting</title>
      </Helmet>
      <NavBar />
      <div className="home">
        <Hero />
        <Services />
        <About />
        <About2 />


        <Culture />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  const brands = [
    {
      name: "brand1",
      imgUrl: "assets/brand1.png",
    },
    {
      name: "brand2",
      imgUrl: "assets/brand2.png",
    },
    {
      name: "brand3",
      imgUrl: "assets/brand3.png",
    },
    {
      name: "brand4",
      imgUrl: "assets/brand4.png",
    },
    {
      name: "brand5",
      imgUrl: "assets/brand5.png",
    },
  ];

  return (
    <>
      <section className="section-global no-border">


        <div className="container">


          <div className="row gy-5">
            <div className="col-lg-6 align-self-center">
              <h1>Leave the software development to us.</h1>
              <p className="txt-1 mt-16 me-lg-5">
                You should be focused on generating income, gaining users,
                planning strategies for your business. Leave the dirty work of
                developing to us.
              </p>
              <form className="mt-20 me-lg-5">
                <div className="cta-form d-flex align-items-center justify-content-between">


                  <div className="cta-input">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="cta-btn">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Get a demo
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 align-self-center">
              {/* <img src={require('./assets/hero_image.png')} className="img-fluid" alt="hero" /> */}

              <dotlottie-player
                src="https://lottie.host/aeb67ff7-c67b-4de4-96eb-791643a3039d/1kyb2Jy6aR.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></dotlottie-player>
            </div>
          </div>
        </div>
      </section>
      <BrandSection brands={brands} src={"home"} bordered />
    </>
  );
};

const Services = () => {
  const services = [
    {
      name: "Cryptocurrency & Blockchain",
      info: "Smart contracts, Decentralized Applications, Custom Blockchain Development, Cryptocurrency Wallet Development",
      link: "/services",
      icoUrl: "assets/bitcoin.png",
    },
    {
      name: "Ecommerce Solutions",
      info: "Shopify, Woocommerce, Multi-vendor apps, Delivery apps",
      link: "/services",
      icoUrl: "assets/online-marketing.png",
    },
    {
      name: "Education & Learning Management System",
      info: "Learning management systems, gamified learning, e-learning content, school management system.",
      link: "/services",
      icoUrl: "assets/video-lecture.png",
    },
    {
      name: "HealthTech: Clinic Management System",
      info: "Doctor booking app, Telemedicine, Healthcare IoT, Healthcare Analytics, Medical Imaging.",
      link: "/services",
      icoUrl: "assets/cardiogram.png",
    },
    {
      name: "Church Management Systems",
      info: "Online giving platform, Online Bible school, Prayer wall & church community, volunteer management system",
      link: "/services",
      icoUrl: "assets/church.png",
    },
    {
      name: "AI & Chatbots",
      info: "Chatbot CRM, Business automation, Business Intellignence dashboard",
      link: "/services",
      icoUrl: "assets/ai.png",
    },
    {
      name: "ERP Solution",
      info: "Supply chain management, CRM management, Process line manufacturing, Warehouse management",
      link: "/services",
      icoUrl: "assets/erp.png",
    },
    {
      name: "Business Intelligence",
      info: "interactive dashboards, ad-hoc reporting, scheduled report generation, and drill-down capabilities.",
      link: "/services",
      icoUrl: "assets/business-intelligence.png",
    },
  ];
  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">
                Examples of what we've done
              </div>
              <h2>Best in class innovative tech solutions</h2>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <div
                className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"
                key={i}
              >
                <ServiceCards data={service} src="home" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const About = () => {
  const features_data = [
    "Seamless, comprehensive, and cost-effective",
    "Connect with your customers",
    "Streamline your operations",
  ];

  return (
    
    <>
      <section className="section-global bg-shade-1">

        <div className="container">
          <div className="row mb-40 gy-4">
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about1.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Our Mantra</div>
              <h2 className="mb-16">Stable. Functional. Beautiful solutions</h2>
              <p className="txt-1">
                We've done this a hundred times. We know what you want. Let us
                build something that satisfies you, your boss, and your users.
                <br />
                <br />
                We aim for a stable system that satisfies your customers, the
                right price that satisfies your boss, and the best client
                servicing that satisfies you.
              </p>
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-5 gy-4">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Solutions</div>
              <h2 className="mb-16">Build apps that make sense and money</h2>
              <p className="txt-1">
                What's more important than a beautiful app? An app that
                generates revenue.
                <br />
                <br />
                Our expertise and consultation includes advising you on the best
                ways forward to profitability. We believe that we only make
                money when you do. From optimizing user flows to implementing
                effective monetization models, we'll guide you every step of the
                way.
              </p>
              {features_data.map((e, i) => (
                <div
                  key={i}
                  className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {e}
                </div>
              ))}
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about2.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const About2 = () => {
  const features_data = [
    "Seamless, comprehensive, and cost-effective",
    "Connect with your customers",
    "Streamline your operations",
  ];

  return (
    <>
      <section className="section-global bg-shade-1">
        <div className="container">
          <div className="row mb-40 gy-4">
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about3.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Growth</div>
              <h2 className="mb-16">Feel our love for you</h2>
              <p className="txt-1">
                What can you expect from us? We promise, prompt replies, honest
                constructive feedback, loyalty to you.
                <br />
                <br />
                We truly want to best for you. Because again, when you make
                money, we do too.
                <br />
                <br />
                From initial concept to final deployment and beyond, we work
                tirelessly to understand your unique needs, exceed your
                expectations, and deliver software solutions that not only meet
                but surpass your goals.
                <br />
                <br />
                We truly want to best for you. Because again, when you make
                money, we do too. We're always aiming for partnerships that last
                years to come.
              </p>
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="row pt-5 gy-4">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Solutions</div>
              <h2 className="mb-16">Deliver personalized solutions at scale</h2>
              <p className="txt-1">
                We craft digital experiences that resonate with your audience
                and grow your business. If you have a vision for the next big
                thing, we can help you make it happen.{" "}
              </p>
              {features_data.map((e, i) => (
                <div
                  key={i}
                  className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {e}
                </div>
              ))}
              <div className="mt-16">
                <Link to="/about" className="arrow-link">
                  Learn more
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img
                  src={require("./assets/about4.png")}
                  className="img-fluid"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Culture = () => {
  const culture_data = [
    {
      imgUrl: "./assets/culture_1.png",
      title: "Strategy",
      text: "We not only care about product goals but business goals as well. We weigh important business factors and account them accordingly into our development plan",
    },
    {
      imgUrl: "./assets/culture_2.png",
      title: "Design",
      text: "Crafting visually stunning and intuitive interfaces is our passion. Our UI/UX design expertise ensures seamless digital experiences that engage and delight users.",
    },
    {
      imgUrl: "./assets/culture_3.png",
      title: "Development",
      text: "Elevate your business with cutting-edge software solutions meticulously crafted by our expert development team.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row mb-40">
            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2 text-center">
              <div className="section-tag mb-8">Services</div>
              <h2>What we can do for you</h2>
            </div>
          </div>
          <div className="row">
            {culture_data.map((e, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 text-center" key={i}>
                <img
                  src={require(`${e.imgUrl}`)}
                  className="img-fluid"
                  alt={e.title}
                />
                <h3>{e.title}</h3>
                <p className="txt-2">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Projects = () => {
  const projects_data = [
    {
      category: "development",
      text: "LAPI - Single entry point API management",
      imgUrl: "assets/project1.png",
      color: "#F97316",
      link: "/project-detail",
    },
    {
      category: "design",
      text: "How Deko manages to scale using Stact",
      imgUrl: "assets/project2.png",
      color: "#2D7EF8",
      link: "/project-detail",
    },
    {
      category: "branding",
      text: "Teno's journey from small to giant",
      imgUrl: "assets/project3.png",
      color: "#00AA45",
      link: "/project-detail",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40 justify-content-between gy-4">
            <div className="col-xl-5 col-lg-5">
              <div className="section-tag mb-8">Case Studies</div>
              <h2>Our recently compeleted projects</h2>
            </div>
            <div className="col d-flex align-self-center">
              <Link
                to="/projects"
                className="btn btn-outline btn-arrow ms-lg-auto"
              >
                See all case studies
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                    fill="black"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="row">
            <ProjectCards data={projects_data} src="landing2" />

          </div>
        </div>
      </section>
    </>
  );
};

const InfoSection = () => {
  const infoData = [
    {
      title: "Customer Research",
      text: "We mine data, uncover insights and identify opportunities",
    },
    {
      title: "User friendly Build",
      text: "We architect websites and apps to solve user problems",
    },
    {
      title: "Scalable models",
      text: "Continuous Delivery pipelines to ensure fast iteration",
    },
  ];

  return (
    <>
      <section className="section-global bg-shade-green">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Why choose Stact?</div>
              <h2 className="mb-16">
                We provide solutions that make our clients' lives simpler
              </h2>
              {infoData.map((e, i) => (
                <div className="d-flex mb-16" key={i}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>
                    <h4 className="mb-0">{e.title}</h4>
                    <div className="txt-2">{e.text}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-6 text-center align-self-center">
              <img
                src={require("./assets/info.png")}
                className="img-fluid info-banner-image"
                alt="Why stact"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Home;
